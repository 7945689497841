.animated {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.characters-animation{
    -webkit-animation-name: sample-animation;
    animation-name: sample-animation;
}

@-webkit-keyframes sample-animation {
  from {

  }

  50% {

  }

  to {

  }
}

@keyframes sample-animation {
  from {

  }

  50% {

  }

  to {

  }

}





