

/*@font-face {
    font-family: 'fontello';
    src: url($asset_path + '/fonts/fontello.eot?44688758');
    src: url($asset_path + '/fonts/fontello.eot?44688758#iefix') format('embedded-opentype'),
    url($asset_path + '/fonts/fontello.woff2?44688758') format('woff2'),
    url($asset_path + '/fonts/fontello.woff?44688758') format('woff'),
    url($asset_path + '/fonts/fontello.ttf?44688758') format('truetype'),
    url($asset_path + '/fonts/fontello.svg?44688758#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

@font-face {
    font-family: 'aaux_next';
    src: url('http://odone.design/assets/fonts/aauxnext_light-webfont.woff2') format('woff2'),
    url('http://odone.design/assets/fonts/aauxnext_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'aaux_next';
    src: url('http://odone.design/assets/fonts/aauxnext_regular-webfont.woff2') format('woff2'),
    url('http://odone.design/assets/fonts/aauxnext_regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'aaux_next';
    src: url('http://odone.design/assets/fonts/aauxnext_semibold-webfont.woff2') format('woff2'),
    url('http://odone.design/assets/fonts/aauxnext_semibold-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'BrownStd';
    src: url("http://odone.design/assets/fonts/BrownStd-Regular.eot?#iefix") format("embedded-opentype"), url("http://odone.design/assets/fonts/BrownStd-Regular.woff") format("woff"), url("http://odone.design/assets/fonts/BrownStd-Regular.ttf") format("truetype"), url("http://odone.design/assets/fonts/BrownStd-Regular.svg#BrownStd-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrownStd';
    src: url("http://odone.design/assetsBrownStd-Light.eot?#iefix") format("embedded-opentype"), url("http://odone.design/assets/fonts/BrownStd-Light.woff") format("woff"), url("http://odone.design/assets/fonts/BrownStd-Light.ttf") format("truetype"), url("http://odone.design/assets/fonts/BrownStd-Light.svg#BrownStd-Light") format("svg");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'BrownStd';
    src: url("http://odone.design/fonts/BrownStd-Bold.eot?#iefix") format("embedded-opentype"), url("http://odone.design/assets/fonts/BrownStd-Bold.woff") format("woff"), url("http://odone.design/assets/fonts/BrownStd-Bold.ttf") format("truetype"), url("http://odone.design/assets/fonts/BrownStd-Bold.svg#BrownStd-Bold") format("svg");
    font-weight: 600;
    font-style: normal;
}




html, body{
    width:100%;
    height:100%;
    font-family: 'aaux_next', sans-serif;
    margin:0;
    /*webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;*/
}

body{
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;

    opacity:1;
}


.bold{
    font-weight:bold;
}

.italic{
    font-style: italic;
}


body a{
}

h1,h2,h3,h4, h5{
}

.center {
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.center-vertical {
    position: absolute;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 45;
}

.center-horizontal {
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.center-acelerate3d {
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

.center-vertical-acelerate3d {
    position: absolute;
    top: 50%;
    -ms-transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    z-index: 45;
}

.center-horizontal-acelerate3d {
    position: absolute;
    left: 50%;
    -ms-transform: translate3d(-50%, 0, 0);
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.clear{
    clear:both;
}


.loading{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: #fff;
    z-index:2400;
    pointer-events: none;

    -webkit-transition: visibility 0.5s, opacity 0.5s;
    transition: visibility 0.5s, opacity 0.5s;
    opacity: 0;
    visibility:hidden;
    &.show{
        opacity: 1;
        visibility:visible;
    }

}

.loading-curtain{
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;

    z-index:2000;
    -webkit-transition-property: width,height;
    transition-property: width,height;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: cubic-bezier(.85,0,.05,1);
    transition-timing-function: cubic-bezier(.85,0,.05,1);
    padding:20px;

    .content-cont{
        position: relative;
        width: 100%;
        height:100%;
    }


    .loading-curtain-child{
        opacity:0.5;
        background-color: rgba(144, 146, 146, 1);
        -webkit-transition: all 1s cubic-bezier(.85,0,.05,1);
        transition: all 1s cubic-bezier(.85,0,.05,1);
        width:100%;
        height:100%;
        overflow: hidden;
    }

    &.final-hide{
        .loading-curtain-child{
            height:0;
            width:0;
        }
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }



    /*-webkit-shape-inside: inset(10px 10px 10px 10px);
    shape-inside: inset(10px 10px 10px 10px);
    -webkit-clip-path: inset(10px 10px 10px 10px);
    clip-path: inset(10px 10px 10px 10px);*/

    &.show{
        /*-webkit-shape-inside: inset(0 0 0 0);
        shape-inside: inset(0 0 0 0);
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);*/
        opacity:1;
        padding:0;

    }

}




.quad{
    z-index:2250;

    overflow: hidden;
    width:0;
    height:200px;
    margin-left: -50px;

    &.show{
        width:0;
    }

    .img-loading{
        z-index:2200;
        width:100px;
        margin-left: 50px;
    }
}

.quad-1{

    margin-left: -50px;
    margin-top:50px;

    .img-loading{
        margin-left: 50px;
        margin-top:-50px;
    }
}

.quad-2{

    margin-left: 50px;
    margin-top:50px;

    .img-loading{
        margin-left: -50px;
        margin-top:-50px;
    }
}

.quad-3{

    margin-left: -50px;
    margin-top:-50px;

    .img-loading{
        margin-left: 50px;
        margin-top:50px;
    }
}

.quad-4{

    margin-left: 50px;
    margin-top:-50px;

    .img-loading{
        margin-left: -50px;
        margin-top:50px;
    }
}




.menu-btn{
    position:fixed;
    cursor: pointer;
    z-index:2400;



    &:active{
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    top:0;
    right:0;
    padding:30px;
    width:50px;
    height:34px;
    box-sizing: content-box;

    .sub-menu-btn{
        width:100%;
        height:100%;
        position: relative;
    }

    .line{
        position:absolute;
        background-color: #fff;
        width:50px;
        height:1px;
        right:0;

        .menu-open &{
            @media (max-width: 767px) {
                background-color: rgba(144, 146, 146, 1);
            }

            &.white{
                background-color: #fff;
            }
        }


        -webkit-transition-property: opacity, -ms-transform, -webkit-transform, transform;
        transition-property: opacity, -ms-transform, -webkit-transform, transform;

        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;

        -ms-transform: rotate(0deg) translate3d(0, 0, 0);
        -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
        transform: rotate(0deg) translate3d(0, 0, 0);
        opacity:1;

        &.line-1{
            top:0
        }
        &.line-2{
            top:10px
        }
        &.line-3{
            top:20px
        }
        &.line-4{
            top:30px
        }
    }

    /*&:hover{
        .line-1{
            -ms-transform: rotate(0deg) translate3d(2px, 0, 0);
            -webkit-transform: rotate(0deg) translate3d(2px, 0, 0);
            transform: rotate(0deg) translate3d(2px, 0, 0);
        }
        .line-2{
            -ms-transform: rotate(0deg) translate3d(-2px, 0, 0);
            -webkit-transform: rotate(0deg) translate3d(-2px, 0, 0);
            transform: rotate(0deg) translate3d(-2px, 0, 0);
        }
        .line-3{
            -ms-transform: rotate(0deg) translate3d(2px, 0, 0);
            -webkit-transform: rotate(0deg) translate3d(2px, 0, 0);
            transform: rotate(0deg) translate3d(2px, 0, 0);
        }
        .line-4{
            -ms-transform: rotate(0deg) translate3d(-2px, 0, 0);
            -webkit-transform: rotate(0deg) translate3d(-2px, 0, 0);
            transform: rotate(0deg) translate3d(-2px, 0, 0);
        }
    }
    */
    .menu-open &{

        .line-1{
            -ms-transform: rotate(360deg) translate3d(0, 0, 0);
            -webkit-transform: rotate(360deg) translate3d(0, 0, 0);
            transform: rotate(360deg) translate3d(0, 0, 0);
            opacity:0;
        }
        .line-2{
            -ms-transform: rotate(315deg) translate3d(-2px, 5px, 0);
            -webkit-transform: rotate(315deg) translate3d(-2px, 5px, 0);
            transform: rotate(315deg) translate3d(-2px, 5px, 0);
        }
        .line-3{
            -ms-transform: rotate(405deg) translate3d(-2px, -5px, 0);
            -webkit-transform: rotate(405deg) translate3d(-2px, -5px, 0);
            transform: rotate(405deg) translate3d(-2px, -5px, 0);
        }
        .line-4{
            -ms-transform: rotate(360deg) translate3d(0, 0, 0);
            -webkit-transform: rotate(360deg) translate3d(0, 0, 0);
            transform: rotate(360deg) translate3d(0, 0, 0);
            opacity:0
        }

    }

}

.lazy-fade{

}

.isotype{
    position:absolute;
    top:30px;
    left:30px;
    width:30px;
    z-index:500;
    opacity:0;
    
    -webkit-transition: all 0.4s;
    transition: all 0.4s;

    .menu-open &{
        filter: invert(.5);

        &.no-invert{
            filter: invert(0);
        }
    }
    &.show{
        opacity:1;
    }


    img{
        width:100%;
    }
}


.menu{
    width:100%;
    height:100%;
    left:0;
    top:0;
    position:fixed;
    z-index:100;
    overflow:hidden;
    -ms-transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity:1;
    webkit-font-smoothing: antialiased;

    -webkit-transition-timing-function: cubic-bezier(.85,0,.05,1);
    transition-timing-function: cubic-bezier(.85,0,.05,1);

    -webkit-transition-property: all;
    transition-property: all;

    -webkit-transition-duration: 0s;
    transition-duration: 0s;

    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;




    font-weight: 200;

    .child-menu{
        width:50%;
        height: 100%;
        position:absolute;
        top:0;

        @media (max-width: 767px) {
            width:100%;
        }

        &.child-menu-animated{

        -webkit-transition-property: all;
        transition-property: all;

        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;

        -webkit-transition-timing-function: cubic-bezier(.85,0,.05,1);
        transition-timing-function:cubic-bezier(.85,0,.05,1);



        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        transform: scale(0);

            &.child-menu-left{
                background-color: rgba(255, 255, 255, 0.65);
                -webkit-transition-delay: 0s;
                transition-delay: 0s;
                @media (max-width: 767px) {
                    background-color: rgba(255, 255, 255, 1) !important;
                }

                .page-project &{
                    background-color: rgba(255, 255, 255, 0.95);
                }
            }

            &.child-menu-right{
                background-color: rgba(144, 146, 146, 0.75);
                -webkit-transition-delay: 0s;
                transition-delay: 0s;
                @media (max-width: 767px) {
                    background-color: rgba(144, 146, 146, 1) !important;
                }

                .page-project &{
                    background-color: rgba(144, 146, 146, 0.95);
                }
            }
        }

        &.child-menu-left{

            opacity:1;
            visibility: visible;

            &.hide-mobile{
                opacity:0;
                visibility: hidden;
                pointer-events: none;
            }


            right:50%;

            @media (max-width: 767px) {
                right:0;
                z-index:40;
            }

            .footer{
                position:absolute;
                bottom:0;
                padding:0  0  26px 30px;

                font-size:14px;

                a{
                    color:#636b6f !important;
                }

                @media (min-width: 1500px) {
                    font-size: 18px;
                    line-height: 24px;
                }

                @media (max-height: 490px) {
                    display:none;
                }

                p{
                    margin-bottom:0;
                    @media (min-width: 1500px) {
                       margin-bottom:5px;
                    }
                }

                .logotype{
                    width:100px;
                    margin-bottom:5px;
                    img{
                        width:100%;
                    }
                    @media (min-width: 1500px) {
                        width:140px;
                        margin-bottom:15px;
                    }
                }
            }

        }

        &.child-menu-right{
            left:50%;

            @media (max-width: 767px) {
                left:0;
                z-index:20;
            }

            -webkit-transition-property: all;
            transition-property: all;

            -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;

            -webkit-transition-timing-function: cubic-bezier(.85,0,.05,1);
            transition-timing-function:cubic-bezier(.85,0,.05,1);


            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;

            -webkit-perspective: 1000px;
            perspective: 1000px;



            .scroll-nav{
                position:absolute;
                left:50%;
                margin-left:-20px;
                width:40px;
                height:40px;
                background-color: transparent;
                z-index: 150;

                text-align:center;
                color:#fff;
                cursor: pointer;
                display:none;

                &.scroll-nav-up{
                    top:10px;


                    .line-1{
                        -ms-transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                        transform: rotate(135deg);
                        left:-5px;
                    }
                    .line-2{
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        left:5px;
                    }
                }

                &.scroll-nav-down{
                    bottom:10px;

                    .line-1{
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        left:-5px;
                    }
                    .line-2{
                        -ms-transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                        left:5px;
                    }
                }
                .inside-scroll-nav{
                    position:relative;
                    width:14px;
                    height:14px;
                    display:inline-block;

                    -webkit-transition-property: all;
                    transition-property: all;

                    -webkit-transition-duration: 0.2s;
                    transition-duration: 0.2s;

                    -ms-transform: translate3d(0, 0, 0);
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);


                    .line{
                        top:20px;
                        height:1px;
                        width:100%;



                        background-color: #fff;
                        position:absolute;


                    }


                }

                &:hover .inside-scroll-nav{
                    -ms-transform: translate3d(0, 2px, 0);
                    -webkit-transform: translate3d(0, 2px, 0);
                    transform: translate3d(0, 2px, 0);
                }

            }

            .container-menu-sections{
                -webkit-transition-property: all;
                transition-property: all;
                position:relative;
                z-index:30;

                -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;

                -webkit-transition-timing-function: cubic-bezier(.85,0,.05,1);
                transition-timing-function:cubic-bezier(.85,0,.05,1);

                width:100%;
                height: 100%;

                -ms-transform: translate3d(0, 0, 0);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);

                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;



                /*&.section-0{
                    -ms-transform: translate3d(0, 0, 0);
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }

                &.section-1{
                    -ms-transform: translate3d(0, -100%, 0);
                    -webkit-transform: translate3d(0, -100%, 0);
                    transform: translate3d(0, -100%, 0);
                }

                &.section-2{
                    -ms-transform: translate3d(0, -200%, 0);
                    -webkit-transform: translate3d(0, -200%, 0);
                    transform: translate3d(0, -200%, 0);
                }

                &.section-3{
                    -ms-transform: translate3d(0, -300%, 0);
                    -webkit-transform: translate3d(0, -300%, 0);
                    transform: translate3d(0, -300%, 0);
                }

                &.section-4{
                    -ms-transform: translate3d(0, -400%, 0);
                    -webkit-transform: translate3d(0, -400%, 0);
                    transform: translate3d(0, -400%, 0);
                }*/
            }


            .sub-child-menu-right{
                width:100%;
                display:block;
                position:absolute;
                height:100%;
                top:0;
                left:0;

                -webkit-transition-property: all;
                transition-property: all;

                -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;

                -webkit-transition-timing-function: cubic-bezier(.85,0,.05,1);
                transition-timing-function:cubic-bezier(.85,0,.05,1);

                -ms-transform: scale(2);
                -webkit-transform: scale(2);
                transform: scale(2);

                @media (max-width: 767px) {
                    -ms-transform: scale(1);
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    -webkit-transition-duration: 0s;
                    transition-duration: 0s;
                }


                opacity:0;
                visibility:hidden;
                pointer-events: none;


                overflow:auto;

                .projects {
                    position:relative;
                    z-index:30;
                    padding:100px 30px;
                    width:80%;

                    a{
                        text-decoration: none;
                    }


                    .project {
                        cursor: pointer;
                        position: relative;
                        padding-top:22px;

                        .title {
                            font-size: 20px;
                            margin: 0;
                            color:#fff;
                            -webkit-transition-property: margin-left, width;
                            transition-property: margin-left, width;
                            -webkit-transition-duration: 0.2s;
                            transition-duration: 0.2s;

                            @media (min-width: 768px) and (max-width: 991px){
                                font-size: 16px;
                            }

                            @media (min-width: 1500px) {
                                font-size: 24px;
                            }

                        }

                        .line{

                            width:0;
                            position:absolute;
                            float:left;
                            height:1px;
                            top:38px;
                            left:0;
                            background-color:#fff;
                            -webkit-transition-property: margin-left, width;
                            transition-property: margin-left, width;
                            -webkit-transition-duration: 0.2s;
                            transition-duration: 0.2s;
                        }

                        .location {
                            font-size: 13px;
                            padding-top: 4px;
                            padding-bottom:11px;
                            color: #fff;
                            margin:0;

                            -webkit-transition-property: color;
                            transition-property: color;
                            -webkit-transition-duration: 0.2s;
                            transition-duration: 0.2s;

                            @media (min-width: 1500px) {
                                font-size: 16px;
                            }
                        }

                        &:hover {
                            .line {
                                width: 5px;
                            }
                            .title {
                                margin-left: 10px;
                            }
                            .location {

                            }
                        }
                    }
                }

                &.sub-child-menu-right-0{
                    .text{
                        letter-spacing: 1px;
                        @media (min-width: 768px) and (max-width: 991px){
                            font-size: 16px;
                        }
                    }
                    .signature{
                        @media (min-width: 768px) and (max-width: 991px){
                            font-size: 18px;
                        }
                    }
                }


                &.sub-child-menu-right-1{


                }

                &.sub-child-menu-right-2{
                    .text{
                        margin-bottom: 50px;
                        letter-spacing: 1px;
                        @media (min-width: 768px) and (max-width: 991px){
                            font-size: 16px;
                        }

                    }
                    .team{
                        .member{
                            margin:20px 0;
                            cursor: pointer;
                            position:relative;
                            .name{
                                text-transform: uppercase;
                                -webkit-transition-property: margin-left, width;
                                transition-property: margin-left, width;
                                -webkit-transition-duration: 0.2s;
                                transition-duration: 0.2s;

                                font-size: 18px;

                                @media (min-width: 1500px) {
                                    font-size: 22px;
                                }
                            }

                            .job{
                                font-size: 14px;
                                @media (min-width: 1500px) {
                                    font-size: 16px;
                                }
                            }

                            .line{
                                width:0;
                                position:absolute;
                                float:left;
                                height:1px;
                                top:15px;
                                left:0;
                                background-color:#fff;
                                -webkit-transition-property: margin-left, width;
                                transition-property: margin-left, width;
                                -webkit-transition-duration: 0.2s;
                                transition-duration: 0.2s;
                            }

                            &:hover {
                                .line {
                                    width: 5px;
                                }
                                .name {
                                    margin-left: 10px;
                                }
                                .location {

                                }
                            }
                        }
                    }

                    .member-card{
                        display:none;
                        position:absolute;
                        width:100%;
                        height:100%;
                        top:0;
                        left:0;
                        background-position: center center;
                        background-size: cover;

                        .member-values{
                            width:50%;
                            position:absolute;
                            bottom:0;
                            padding:0 0 30px 30px;

                            .name{
                                text-transform: uppercase;
                                color:#000;
                                margin-bottom: -4px;
                            }

                            .job{
                                color:#000;
                            }

                            .text{
                                margin-top:30px;
                                color:#fff;
                                @media (min-width: 768px) and (max-width: 991px){
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                &.sub-child-menu-right-3{

                    .text-start-3{
                        width:100%;
                        padding: 30px;
                        color: #fff;
                        padding-top:100px;



                        @media (max-width: 767px){
                            width:100%;
                        }

                        .service{
                            width:60%;
                            margin-bottom:20px;
                            @media (min-width: 768px) and (max-width: 991px){
                                width:65%;
                            }

                            @media (max-width: 767px){
                                width:100%;
                            }

                            .signature{
                                font-size:15px !important;
                                font-weight: bold !important;

                                text-transform:uppercase;
                                @media (min-width: 768px) and (max-width: 991px){
                                    font-size: 12px !important;
                                }

                                @media (min-width: 1500px) {
                                    font-size: 18px !important;
                                    line-height: 20px;
                                }
                            }

                            .text{
                                font-size:14px;
                                font-family: 'aaux_next', sans-serif;
                                margin-top:6px;
                                line-height: 16px;
                                @media (min-width: 1500px) {
                                    font-size: 16px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }

                }
                &.sub-child-menu-right-4{

                    .text-start{
                        &.scaleIn{
                            -ms-transform: scale(1);
                            -webkit-transform: scale(1);
                            transform: scale(1);
                            opacity:1;
                            visibility:visible;
                        }



                        -webkit-transition-property: all;
                        transition-property: all;

                        -webkit-transition-duration: 0.4s;
                        transition-duration: 0.4s;

                        -webkit-transition-timing-function: cubic-bezier(.85,0,.05,1);
                        transition-timing-function:cubic-bezier(.85,0,.05,1);

                        -ms-transform: scale(2);
                        -webkit-transform: scale(2);
                        transform: scale(2);
                        opacity:0;
                        visibility:hidden;
                    }


                    .private-text-1{
                        font-family: 'aaux_next', sans-serif;
                        font-weight: 500;
                        font-size:14px;
                        margin-bottom:14px;

                        @media (min-width:1500px){
                            font-size:18px;
                        }
                    }
                    .private-text-2{
                        font-family: 'aaux_next', sans-serif;
                        font-weight: 400;
                        font-size:14px;
                        max-width:340px;
                        margin-bottom:14px;
                        @media (min-width:1500px){
                            font-size:18px;
                            max-width:400px;
                        }
                    }
                    .form-private{
                        position: relative;
                        #private-pass{
                            background-color: transparent;
                            border:1px solid #fff;
                            width:100%;
                            padding: 6px 42px 6px 9px;

                            &:focus{
                                outline: none;
                            }
                        }
                        .arrow{
                            position: absolute;
                            right:0;
                            top:4px;
                            width:36px;
                            height:28px;
                            border-left:1px solid #fff;
                            cursor: pointer;


                            &:hover .sub-arrow{
                                -ms-transform: translate(0,0) rotate(495deg);
                                -webkit-transform: translate(0,0) rotate(495deg);
                                transform: translate(0,0) rotate(495deg);
                            }

                            .sub-arrow{
                                -ms-transform: translate(0,0) rotate(135deg);
                                -webkit-transform: translate(0,0) rotate(135deg);
                                transform: translate(0,0) rotate(135deg);
                                width:10px;
                                height:10px;
                                position:absolute;
                                top:9px;
                                left:9px;
                                -webkit-transition: all 0.2s;
                                transition: all 0.2s;



                                .line-1{
                                    height:1px;
                                    width:10px;
                                    top:0;
                                    left:0;
                                    background-color:#fff;
                                }
                                .line-2{
                                    height:10px;
                                    width:1px;
                                    top:0;
                                    right:0;
                                    background-color:#fff;
                                }
                            }
                        }

                    }
                    .list-private{
                        font-weight: 300;

                        .private-text-3{
                            padding:6px 0;
                            border-bottom:1px solid #ccc;
                        }

                        .row-list {

                            a{
                                color:#fff;

                            }

                            .file{

                                cursor: pointer;
                                padding:6px 0;

                                border-bottom:1px solid #ccc;




                                .file-child{
                                    padding:0;
                                    font-weight: 300;
                                    width:50%;

                                    -ms-transform: translate3d(0, 0, 0);
                                    -webkit-transform: translate3d(0, 0, 0);
                                    transform: translate3d(0, 0, 0);
                                    -webkit-transition-property: all;
                                    transition-property: all;
                                    -webkit-transition-timing-function: ease-out;
                                    transition-timing-function: ease-out;
                                    -webkit-transition-duration: 0.2s;
                                    transition-duration: 0.2s;

                                    color:#fff;

                                    &.boldy{
                                        font-weight: 400;
                                    }
                                }

                                &:hover .file-child{
                                    -ms-transform: translate3d(5px, 0, 0);
                                    -webkit-transform: translate3d(5px, 0, 0);
                                    transform: translate3d(5px, 0, 0);
                                }
                            }

                        }

                    }


                }

                &.scaleIn{
                    -ms-transform: scale(1);
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    opacity:1;
                    visibility:visible;
                    pointer-events: auto;
                }

                &.scaleOut{
                    -ms-transform: scale(0);
                    -webkit-transform: scale(0);
                    transform: scale(0);

                    opacity:0;
                    visibility:hidden;
                }







            }

        }

    }

    .menu-open &{

        -ms-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        -webkit-transition-delay: 0s;
        transition-delay: 0s;

        .child-menu-animated{
            -ms-transform: scale(1);
            -webkit-transform: scale(1);
            transform: scale(1);

            &.child-menu-left{
                -webkit-transition-delay: 0s;
                transition-delay: 0s;
            }
            &.child-menu-right{
                -webkit-transition-delay: 0s;
                transition-delay: 0s;
            }

        }

    }

    .link-0{
        -webkit-transform:translate(10px, 0);
        transform:translate(10px, 0);
        -webkit-transition-property: margin-left, opacity, color, -ms-transform, -webkit-transform, transform;
        transition-property: margin-left, opacity, color, -ms-transform, -webkit-transform, transform;

        -webkit-transition-duration: 0.2s, 0.2s, 0.4s, 0.4s, 0.4s, 0.4s;
        transition-duration: 0.2s, 0.4s, 0.2s, 0.4s, 0.4s, 0.4s;

        -webkit-transition-timing-function: ease, ease-out, ease-out, ease-out, ease-out, ease-out;
        transition-timing-function: ease, ease-out, ease-out, ease-out, ease-out, ease-out;


        .menu-open & {

            -webkit-transition-delay: 0s, 0.1s, 0s, 0.1s, 0.1s, 0.1s;
            transition-delay: 0s, 0.1s, 0s, 0.1s, 0.1s, 0.1s;
        }
    }


    .container-links{
        position:absolute;
        left:30px;
        top:90px;
        width:160px;

        @media (max-width:767px){
            width:220px;
            width: calc(100% - 63px);
        }

        @media (min-width: 1500px) {
            width:260px;
        }

        .link {
            font-weight: 200;
            font-size: 14px;
            margin: 6px 0;
            cursor: pointer;
            color: rgba(117, 119, 119, 1);

            -webkit-transition-property: margin-left, opacity, color, -ms-transform, -webkit-transform, transform;
            transition-property: margin-left, opacity, color, -ms-transform, -webkit-transform, transform;

            -webkit-transition-duration: 0.2s, 0.2s, 0.4s, 0.4s, 0.4s, 0.4s;
            transition-duration: 0.2s, 0.4s, 0.2s, 0.4s, 0.4s, 0.4s;

            -webkit-transition-timing-function: ease, ease-out, ease-out, ease-out, ease-out, ease-out;
            transition-timing-function: ease, ease-out, ease-out, ease-out, ease-out, ease-out;

            opacity: 0;

            -webkit-transition-delay: 0s, 0.2s, 0s, 0.2s, 0.2s, 0.2s;
            transition-delay: 0s, 0.2s, 0s, 0.2s, 0.2s, 0.2s;

            -ms-transform: translate3d(0, 30px, 0);
            -webkit-transform: translate3d(0, 30px, 0);
            transform: translate3d(0, 30px, 0);

            @media (max-width: 768px) {
                font-size: 18px;
            }

            @media (min-width: 1500px) {
                font-size: 20px;
            }

            &.active {
                color: #000;
            }

            .menu-open & {
                opacity: 1;

                -ms-transform: translate3d(0, 0, 0);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);

                &:hover {
                    margin-left: 3px;
                }



                &.link-1 {
                    -webkit-transition-delay: 0s, 0.2s, 0s, 0.2s, 0.2s, 0.2s;
                    transition-delay: 0s, 0.2s, 0s, 0.2s, 0.2s, 0.2s;
                }
                &.link-2 {
                    -webkit-transition-delay: 0s, 0.3s, 0s, 0.3s, 0.3s, 0.3s;
                    transition-delay: 0s, 0.3s, 0s, 0.3s, 0.3s, 0.3s;
                }
                &.link-3 {
                    -webkit-transition-delay: 0s, 0.4s, 0s, 0.4s, 0.4s, 0.4s;
                    transition-delay: 0s, 0.4s, 0s, 0.4s, 0.4s, 0.4s;
                }

                &.link-4 {
                    -webkit-transition-delay: 0s, 0.5s, 0s, 0.5s, 0.5s, 0.5s;
                    transition-delay: 0s, 0.5s, 0s, 0.5s, 0.5s, 0.5s;
                }


            }

        }

        .links {
            padding: 6px 0;

        }

        .private {
            padding: 6px 0;
        }

        .lang-buttons {
            position:absolute;
            bottom:-36px;
            left:0;
            @media (min-width:1500px){
                bottom:-50px;
            }
            .lang-button {
                border-radius: 50%;
                border: 2px solid rgba(117, 119, 119, 0.75);
                cursor: pointer;
                text-align: center;
                width:28px;
                height:28px;
                font-weight: bold;
                color:rgba(117, 119, 119, 0.75);
                background-color: transparent;
                font-size: 10px;
                margin-right: 4px;



                -webkit-transition-property: margin-left, opacity, background-color, color, -ms-transform, -webkit-transform, transform;
                transition-property: margin-left, opacity, background-color, color, -ms-transform, -webkit-transform, transform;

                -webkit-transition-timing-function: ease, ease-out, ease-out, ease-out, ease-out, ease-out, ease-out;
                transition-timing-function: ease, ease-out, ease-out, ease-out, ease-out, ease-out, ease-out;

                -webkit-transition-duration: 0.2s, 0.4s, 0.2s, 0.2s, 0.4s, 0.4s, 0.4s;
                transition-duration: 0.2s, 0.4s, 0.2s, 0.2s, 0.4s, 0.4s, 0.4s;

                padding:4px;

                @media (min-width:1500px){
                    width:36px;
                    height:36px;
                    font-size: 14px;
                    padding:6px;
                }

                display:inline-block;
                opacity: 0;

                -ms-transform: translate3d(0, 30px, 0);
                -webkit-transform: translate3d(0, 30px, 0);
                transform: translate3d(0, 30px, 0);

                &.active{
                    border: 2px solid rgba(117, 119, 119, 1);
                    color:#ddd;
                    background-color:rgba(117, 119, 119, 1);
                    text-decoration: none;
                }

                &:hover{
                    border: 2px solid rgba(117, 119, 119, 0.9);
                    color:#ddd;
                    background-color:rgba(117, 119, 119, 0.9);
                    text-decoration: none;
                }

                .menu-open & {

                    opacity: 1;

                    -ms-transform: translate3d(0, 0, 0);
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);

                    &.lang-1 {
                        -webkit-transition-delay: 0s, 0.6s, 0s, 0s, 0.6s, 0.6s, 0.6s;
                        transition-delay: 0s, 0.6s, 0s, 0s, 0.6s, 0.6s, 0.6s;
                    }
                    &.lang-2 {
                        -webkit-transition-delay: 0s, 0.7s, 0s, 0s, 0.7s, 0.7s, 0.7s;
                        transition-delay: 0s, 0.7s, 0s, 0s, 0.7s, 0.7s, 0.7s;
                    }
                }
            }
        }


        .links-line{
            width: 0;
            height:1px;
            background-color:rgba(117, 119, 119, 1);
            position:absolute;
            left:0;

            &.links-line-1{
                top:0;
            }
            &.links-line-2{


                top:103px;
                @media (max-width: 768px) {top: 119px;}
                @media (min-width: 1500px) {top: 130px;}
            }
            &.links-line-3{
                bottom:0;
            }

            -webkit-transition-property: width;
            transition-property: width;

            -webkit-transition-timing-function: ease-out;
            transition-timing-function:ease-out;

            -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;

            -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;

            .menu-open &{

                -ms-transform: translate3d(0, 0, 0);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);

                -webkit-transition-duration: 1.2s;
                transition-duration: 1.2s;

                width:100%;

                &.links-line-1{
                    -webkit-transition-delay: 1s;
                    transition-delay: 1s;
                }
                &.links-line-2{
                    -webkit-transition-delay: 1.2s;
                    transition-delay: 1.2s;
                }
                &.links-line-3{
                    -webkit-transition-delay: 1.4s;
                    transition-delay: 1.4s;
                }
            }
        }
    }

    .text-start{
        position:absolute;
        left:30px;
        top:100px;
        width:80%;
        max-width:400px;
        color:#fff;

        @media (min-width:1500px){
            max-width:600px;
        }

        .text{
            font-family: 'aaux_next', sans-serif;
            font-size:17px;
            line-height:22px;

            -webkit-transition-property: all;
            transition-property: all;

            -webkit-transition-timing-function: ease-out;
            transition-timing-function:ease-out;

            -webkit-transition-duration: 0.6s;
            transition-duration: 0.6s;

            -webkit-transition-delay: 0.6s;
            transition-delay: 0.6s;

            @media (min-width:1500px){
                font-size:20px;
                line-height:28px;
                font-weight: normal;
            }

            opacity:0;
            visibility: hidden;
            -ms-transform: translate3d(0, 10px, 0);
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);

            .menu-open &{
                opacity:1;
                visibility: visible;
                -ms-transform: translate3d(0, 0, 0);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }

        .signature{
            margin-top:12px;
            font-size:20px;
            -webkit-transition-property: all;
            transition-property: all;

            @media (min-width:1500px){
                font-size:26px;
            }

            -webkit-transition-timing-function: ease-out;
            transition-timing-function:ease-out;

            -webkit-transition-duration: 0.6s;
            transition-duration: 0.6s;

            -webkit-transition-delay: 0.9s;
            transition-delay: 0.75s;

            opacity:0;
            visibility: hidden;
            -ms-transform: translate3d(0, 10px, 0);
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);

            .menu-open & {
                opacity: 1;
                visibility: visible;
                -ms-transform: translate3d(0, 0, 0);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

.image-project{
    z-index:20;
    position:absolute;
    top:0;
    right:0;
    width:100%;
    height: 100%;
    text-align: center;
    background-position:center center;
    background-size:cover;
    background-repeat: no-repeat;


    transition: opacity 0.2s,visibility 0.2s;

    /*-webkit-transition: background-image 0.05s ease-in-out;
    transition: background-image 0.05s ease-in-out;*/


    opacity:0;
    visibility: hidden;

    &.show{
        opacity:0.7;
        visibility: visible;
    }


    img{
        max-width:100%;
        max-height:80%;
        top:50%;
        right:0;


    }
}










