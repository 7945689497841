.page-project{

    background-color: #fff;

    overflow: visible;

    .container-fluid{
        opacity:1;

        -webkit-transition: opacity 1s ease;
        transition: opacity 1s ease;
    }

    .isotype{
        position:fixed;
        filter: invert(.5);
        opacity: 1 !important;

        @media (max-width: 991px) {
            position:absolute;
        }
    }

    .row-title{

        margin:100px 0 30px;
        .location{
            margin:0;
            font-size:14px;
            font-weight: 200;

            @media (min-width: 1500px) {
                font-size: 20px;
            }
        }
        .title{
            margin: 10px 0;
            color: #808080;
            font-family: 'aaux_next', sans-serif;
            font-size: 40px;


            @media (max-width: 767px){
                font-size: 28px;
            }

            @media (min-width: 1500px) {
                font-size: 50px;
            }
        }
        .subtitle{
            margin:0;
            font-size:16px;
            font-family: 'aaux_next', sans-serif;
            font-weight: 200;
            color:#808080;


            @media (min-width: 1500px) {
                font-size: 20px;
            }
        }
    }

    .row-main-img{
        img{
            width:100%;
        }
    }

    .row-text{
        margin:60px 0;
        font-weight: normal;
        font-family: 'aaux_next', sans-serif;
        font-size:18px;
        color: #808080;

        @media (min-width: 1500px) {
            font-size: 22px;
        }
    }

    .row-img{

        box-sizing: border-box;
        text-align: center;

        .text-align-0{
            text-align:left;
        }
        .text-align-1{
            text-align:center;
        }
        .text-align-2{
            text-align:right;
        }

        .inside-child{
            display:inline-block;
            position: relative;
        }

        .child-row{

            margin-bottom:50px;
            @media (max-width: 991px){

                margin-bottom:20px;
            }

            &.child-flex{
                position:relative;
                margin-bottom:47px;

                @media (max-width: 991px){
                    max-height:none !important;
                    margin-bottom:20px;
                }
            }

            .img-clear{
                clear:both;
                max-width:100%;

                @media (max-width: 991px){
                    max-height:none !important
                }

            }

            .img-flex{
                white-space: nowrap;
                display:block;
                float:left;

                @media (max-width: 991px){
                    width:100% !important;
                    max-width:100% !important;
                    height:auto !important;
                    white-space:normal;
                    float:none;
                    display: inline-block;
                    padding:0 !important;
                    margin-bottom:20px !important;
                }
            }

        }

        .false-cuad{
            position:absolute;
            background-color: #fff;
            height:10px;
            bottom:-7px;
            left:0;
            width:100%;
        }

    }



    .menu-btn{
        .line{
            background-color: #808080;
        }
    }

    &.menu-open{
        .menu-btn{
            .line{
                background-color: #fff;

                @media (max-width: 991px) {
                    background-color: #808080;
                }
            }
        }
    }



    .video-project{

        video{
            width:100%
        }

        .img-cover{
            width: 100%;
            height: 100%;
            z-index: 25;
            position: absolute;
            top: 0;
            left: 0;
            background-position: center center;
            background-size: cover;
            opacity: 0;
            visibility:hidden;

            -webkit-transition: visibility 0.5s, opacity 0.5s;
            transition: visibility 0.5s, opacity 0.5s;

            &.show{
                opacity: 1;
                visibility:visible;
            }
        }

        .play-general{
            font-size: 40px;
            z-index: 100;
            color: #fff;
            cursor: pointer;
            opacity: 0;
            visibility:hidden;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            -ms-transform: translate3d(-50%,-50%,0) scale(1);
            -webkit-transform: translate3d(-50%,-50%,0) scale(1);
            transform: translate3d(-50%,-50%,0) scale(1);

            &:hover{
                -ms-transform: translate3d(-50%,-50%,0) scale(1.1);
                -webkit-transform: translate3d(-50%,-50%,0) scale(1.1);
                transform: translate3d(-50%,-50%,0) scale(1.1);
            }

            &.show{
                opacity: 1;
                visibility:visible;
            }
        }

        .controls{
            position: absolute;
            bottom: 42px;
            z-index: 80;
            -webkit-transition: visibility 0.5s, opacity 0.5s;
            transition: visibility 0.5s, opacity 0.5s;
            opacity: 0;
            visibility:hidden;

            &.show{
                opacity: 1;
                visibility:visible;
            }

            div{
                float:left;
            }

            .play{
                font-size: 25px;
                color: #fff;
                cursor: pointer;
            }

            .time-slider{
                padding: 5px 0;
                margin: 0 18px;

                input{
                    width: 300px;
                    display: block;
                }
            }

            .muted{
                font-size: 25px;
                color: #fff;
                cursor: pointer;

            }

        }
    }

}

.row-more{

    margin:45px 0 90px 0;

    height:60px;
    text-align: center;


    position: relative;
    z-index:50;





    .proj{
        color:#808080;
        display: inline-block;
        width:auto;
        margin-top:90px;
    }

    .row-plus{
        padding:0 30px;
        cursor: pointer;
        display:inline-block;
        &:hover .plus{
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg) ;
            transform: rotate(180deg);
        }

    }
    .plus{
        height:66px;
        width:66px;

        margin-left:-33px;
        left:50%;
        position: absolute;
        top:0;

        -webkit-transition-property: opacity, -ms-transform, -webkit-transform, transform;
        transition-property: opacity, -ms-transform, -webkit-transform, transform;

        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;

        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);

        &:active .line{
            -ms-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }



        .line{

            position:absolute;


            background-color:#808080;

            &.line-bottom{
                height:1px;
                border-radius: 20px;
                width:100%;
                top:50%;
                left:0;
                margin-top:-1px;

            }

            &.line-top{
                height:100%;
                width: 1px;
                border-radius: 20px;
                top:0;
                left:50%;
                margin-left:-1px;
            }
        }
    }
}










