.page-home{
    position:relative;
    overflow:hidden;
    background-color: rgba(144, 146, 146, 1);
    transform: translate(0,0);



    .container-home {
        background-color: #fff;
        @media (max-width: 991px) {
            background-color: rgba(144, 146, 146, 1);
        }
        position:relative;
        height:100%;
        width:100%;
        overflow:hidden;

        .home-video-container {
            background-color: #fff;
            @media (max-width: 991px) {
                background-color: rgba(144, 146, 146, 1);
            }
            height: 100%;

            .play-play{
                cursor: pointer;
                z-index:90;
                color:#fff;
                font-size:50px;
                display:block;
                opacity:0;

                transition: opacity 0.6s;

                &.showing{
                    opacity:1;
                }

                .touchevents &{
                    display:block;
                    /*@media (min-width: 992px) {
                        display:none !important;
                    }*/
                }

                @media (min-width: 992px) {
                    /*display:none !important;*/
                }


            }

            video {
                width: 100%;
                height: 100%;
                opacity: 1;

                @media (max-width: 991px) {
                    opacity:0;

                    &.show{
                        opacity:1;
                    }
                }

            }

            .bg-pattern {
                background-repeat: repeat;
                width: 100%;
                height: 100%;
                z-index: 5;
                position: absolute;
            }
        }
    }

    .know2{
        position: fixed;
        bottom: 30px;
        color: #fff;
        border-bottom: 2px solid #fff;
        font-size: 12px;
        text-transform: lowercase;
        z-index: 300;
        padding: 2px 8px;
        cursor: pointer;
        border-radius: 5px;
        background-color: transparent;
        display: none;
        transition: background-color 0.3s;

        &:hover{
            background-color: rgba(255, 255, 255, 0.18);
            color:#fff;
        }
    }

    .muted{
        font-size:30px;
        position:fixed;
        top:0;
        right:80px;
        padding:30px 20px 20px;
        z-index:2400;
        color:#fff;
        line-height: 0;
        cursor: pointer;

        @media (max-width: 767px){
            /*padding:25px;
            top:45px;
            position: absolute;
            right: 50%;
            -ms-transform: translate(50%, 0);
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
            margin-right:-40px;*/
            /*display:none;*/
        }

    }

    .row-more{

        position: fixed;
        margin-bottom:30px;


        @media (max-width: 992px) {
            display:none;
        }

        bottom:0;

        .line{
            background-color:#fff !important;
        }

        .proj{
            text-transform: uppercase;

        }
        .row-plus{
            opacity:0;
            -webkit-transition: all 0.4s;
            transition: all 0.4s;
            &.show{
                opacity:1;
            }
        }
    }

    &.menu-open{
        .row-more{
            .row-plus{
                opacity:0;
            }
        }

    }
}











